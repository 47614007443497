import _ from 'lodash';
import React, { memo } from 'react';
import { useAsync } from 'react-async-hook';
import { useSelector } from 'react-redux';

import { versions } from 'services';

import * as S from './Footer.styled';

const YEAR = new Date().getFullYear();

const Footer = ({ style = null, type = null }) => {
  const { id: companyId } = useSelector(state => state.company || {});
  const {
    loading,
    result: databases,
  } = useAsync(() => companyId && versions.get(companyId), [companyId]);
  const [globalDB, clientDB] = (_.isArray(databases) && databases) || [];

  return (
    <S.StyledFooter style={style}>
      <S.Container>
        <S.StyledWrapper>

          <S.StyledColumn>
            {!loading && (
              <S.StyledContainer>
                {globalDB && (
                  <div>
                    <span>Database version: </span>
                    <span>{globalDB.versionName}</span>
                  </div>
                )}

                {clientDB && (
                  <div>
                    <span>Client Database version: </span>
                    <span>{clientDB.versionName}</span>
                  </div>
                )}
              </S.StyledContainer>
            )}

            <S.StyledContainer type={type}>
              <span>
                {`Copyright ${YEAR} NIBE | `}
                <S.Anchor
                  href="https://www.nibe-sustainability-experts.com">
                  nibe-sustainability-experts.com
                </S.Anchor>
              </span>
            </S.StyledContainer>
          </S.StyledColumn>
        </S.StyledWrapper>
      </S.Container>
    </S.StyledFooter>
  );
};

export default memo(Footer);
