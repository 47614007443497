import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { Middleware } from 'swr';

import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';

import { versionedApi } from 'services/network';

import { useCurrentMembership } from './useMemberships';

export const useCompany = (companyIdParam: number | 'mine' | null) => {
  const { selectedMembership } = useCurrentMembership();
  const id = Number(companyIdParam === 'mine' ? selectedMembership?.company?.id : companyIdParam);

  const data = useSWR<CompanyDetails>(
    selectedMembership?.id && id ? ['company', id, selectedMembership?.id] : null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (params: unknown[]) => versionedApi.get(`/companies/${params[1]}`),
    { dedupingInterval: 15000, focusThrottleInterval: 60000 },
  );

  return {
    data,
    isSelf: companyIdParam === 'mine' || id === Number(selectedMembership?.company?.id),
    companyId: id,
    isLoading: data.isLoading,
  };
};

const fetcher = (url: string) => versionedApi.get(url.startsWith('/2') ? url.slice(2) : url).then(({
  // @ts-expect-error old api client automatically gives response
  'hydra:member': data,
  // @ts-expect-error same
  'hydra:view': view,
  // @ts-expect-error same
  'hydra:totalItems': totalItems,
}) => (({
  // @ts-expect-error dropdown needs these
  data: data.map(d => ({ ...d, label: d.name, value: d.name })),

  totalItems,
  nextPage: view?.['hydra:next'],
  url,
}) as {nextPage: string,
  totalItems: number, data: CompanyDetails[]}));

  type Params = {
    reviewCompany?: boolean,
    operator?: boolean,
    itemsPerPage?: number
    name?: string,
    isCorp?: boolean,
    corporation?: number
    'order[name]'?: 'asc' | 'desc'
    siblingsOf?: number
  }

export const useCompanies = (params: Params, {
  skip, onError, onSuccess, swrConfig,
}: {
  skip?: boolean
  onSuccess?: (data: Awaited<ReturnType<typeof fetcher>>[], key: string) => void,
  onError?: () => void,
  allowWithoutSelectedMembership?: boolean
  swrConfig?: SWRInfiniteConfiguration
} = {}) => {
  // const canViewCompanies = permissions?.['company.company_childs.view'] || (allowWithoutSelectedMembership && !permissions);
  const stringifiedParams = JSON.stringify(params);
  const getKey = useCallback((index: number, previousPage: {nextPage?: string}) => {
    // if (!canViewCompanies) return null;
    if (previousPage) return previousPage.nextPage;
    if (index > 0) return null;

    const query = new URLSearchParams(JSON.parse(stringifiedParams));
    if (!query.get('itemsPerPage')) {
      query.set('itemsPerPage', '15');
    }

    const siblingsOf = Number(query.get('siblingsOf'));
    query.delete('siblingsOf');
    query.forEach((v, k) => {
      if (!v) {
        query.delete(k);
      }
    });
    query.sort();
    return siblingsOf ? `/company/siblings/${siblingsOf}?${query.toString()}` : `/companies?${query.toString()}`;
  }, [stringifiedParams]);

  const data = useSWRInfinite(
    getKey,
    fetcher,
    {
      dedupingInterval: 5000,
      focusThrottleInterval: 30000,
      revalidateAll: true,
      // isPaused: () => skip || false,
      ...(onSuccess && { onSuccess }),
      ...(onError && { onError }),
      ...(swrConfig || {}),
    },
  );

  return {
    swr: data,
    isLoading: data.isLoading,
    data: data?.data?.flatMap(d => d.data),
    totalItems: data?.data?.[0]?.totalItems,
    loadNextPage: () => data.setSize(s => s + 1),
  };
};
// @ts-expect-error not typed
const companyMiddleware: Middleware = (useSWRNext) => (key, fetcherFn, config) => {
  const res = useSWRNext(key, fetcherFn, config);

  // @ts-expect-error custom prop
  if (!config.includeCompany && !config.includeAllCompaniesOption) return res;
  return {
    ...res,
    // @ts-expect-error not typed
    data: !res.data || res.data?.[0].includesSelf ? res.data : [
      {
        // @ts-expect-error not typed
        ...res.data?.[0],
        data: [
          // @ts-expect-error not typed
          ...(config.includeAllCompaniesOption ? [config.includeAllCompaniesOption] : []),
          // @ts-expect-error not typed
          ...(config.includeCompany ? [config.includeCompany] : []),
          // @ts-expect-error not typed
          ...res.data?.[0].data],
        includesSelf: true,
      },
      // @ts-expect-error not typed
      ...res.data?.slice(1),
    ],
  };
};

export const useCompaniesForDropdown2 = (
  params: Params,
  options?: {skip?: boolean, includeCurrentCompany?: boolean, includeAllCompaniesOption?: boolean},
) => {
  const { includeAllCompaniesOption, includeCurrentCompany } = options || {};
  const [currentSearchTerm, onSearch] = useState<string>(params?.name as string || '');
  useEffect(() => {
    onSearch(params.name as string || '');
  }, [params.name]);

  const { selectedMembership } = useCurrentMembership();
  const company = selectedMembership?.company;

  const { t } = useTranslation();
  const allCompaniesOption = useMemo(() => {
    if (!includeAllCompaniesOption) return null;

    const label = t('all');
    if (!!currentSearchTerm && !label.toLowerCase()
      .includes(currentSearchTerm.toLowerCase())) { return null; }

    return { id: 'all', name: label };
  }, [currentSearchTerm, includeAllCompaniesOption, t]);

  const useMiddleware = (includeCurrentCompany
    && (!currentSearchTerm
      || company?.name?.toLowerCase().includes(currentSearchTerm.toLowerCase())))
    || (!!allCompaniesOption);

  const original = useCompanies({
    ...params,
    name: currentSearchTerm || params.name as string,
  }, {
    skip: options?.skip || false,
    swrConfig: {
      initialSize: 1,
      revalidateFirstPage: false,
      use: useMiddleware ? [companyMiddleware] : undefined,
      // @ts-expect-error this is used in middleware
      includeCompany: includeCurrentCompany && company,
      includeAllCompaniesOption: allCompaniesOption,
    },
  });

  return {
    ...original.swr,
    loadMore: () => original.swr.setSize(s => s + 1),
    onSearch,
  };
};

// Autogenerated type defs here:
export interface CompanyDetails {
  '@context': string
  '@id': string
  '@type': string
  id: number
  name: string
  country: string
  city: string
  postalCode: string
  address: string
  memberships: string[]
  license: number
  logoImage: LogoImage
  reviewerCosts: boolean
  reviewCompany: boolean
  expertUserAddOn: boolean
  protected: boolean
  companyLicenses: CompanyLicense[]
  createdAt: string
  corporation: boolean
  parentCompany: ParentCompany
  relatedCorporation: string
  nibeCustomerNumber: string
  publisher: boolean
  operator: boolean
  publisherNamePerson1: string
  publisherFunctionPerson1: string
  publisherAutographPerson1: PublisherAutographPerson1
  publisherNamePerson2: string
  publisherFunctionPerson2: string
  publisherAutographPerson2: PublisherAutographPerson2
  language: string,
}

export interface LogoImage {
  '@id': string
  '@type': string
  id: number
  contentUrl: string
  filePath: string
  createdAt: string
  lastModifiedAt: string
}

export interface CompanyLicense {
  '@type': string
  id: number
  license: License
}

export interface License {
  '@type': string
  id: number
  code: string
  name: string
  type: string
  description: string
  canShow: boolean
  roles: Role[]
}

export interface Role {
  '@id': string
  '@type': string
  id: number
  name: string
  permissionsAllowed: any[][]
  view: string
}

export interface ParentCompany {
  '@id': string
  '@type': string
  id: number
  name: string
  memberships: string[]
  license: number
  expertUserAddOn: boolean
  protected: boolean
  companyLicenses: CompanyLicense2[]
  createdAt: string
  corporation: boolean
  parentCompany: ParentCompany2
  relatedCorporation: string
  publisher: boolean
  operator: boolean
}

export interface CompanyLicense2 {
  '@type': string
  id: number
  license: License2
}

export interface License2 {
  '@type': string
  id: number
  code: string
  name: string
  type: string
  description: string
  canShow: boolean
  roles: Role2[]
}

export interface Role2 {
  '@id': string
  '@type': string
  id: number
  name: string
  permissionsAllowed: any[][]
  view: string
}

export interface ParentCompany2 {
  '@id': string
  '@type': string
  id: number
  name: string
  memberships: string[]
  license: number
  expertUserAddOn: boolean
  protected: boolean
  companyLicenses: CompanyLicense3[]
  createdAt: string
  corporation: boolean
  publisher: boolean
  operator: boolean
}

export interface CompanyLicense3 {
  '@type': string
  id: number
  license: License3
}

export interface License3 {
  '@type': string
  id: number
  code: string
  name: string
  type: string
  description: string
  canShow: boolean
  roles: Role3[]
}

export interface Role3 {
  '@id': string
  '@type': string
  id: number
  name: string
  permissionsAllowed: any[][]
  view: string
}

export interface PublisherAutographPerson1 {
  '@id': string
  '@type': string
  id: number
  contentUrl: string
  filePath: string
  createdAt: string
  lastModifiedAt: string
}

export interface PublisherAutographPerson2 {
  '@id': string
  '@type': string
  id: number
  contentUrl: string
  filePath: string
  createdAt: string
  lastModifiedAt: string
}
